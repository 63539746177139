import User from '@/api/User';
import { ElMessage } from 'element-plus';

import { ref, reactive, computed } from 'vue';
import Regex from '@/utils/Regex';

export default () => {
  const loading = ref(false);

  const form = reactive({
    oriPassword: '',
    newPassword: '',
    chkPassword: '',
  });

  const check = () => {
    loading.value = true;
    return User.passwordCheck({ phone: form.phone, password: form.password })
      .then((res) => {
        return res;
      })

      .finally(() => {
        loading.value = false;
      });
  };

  const required = (rule, value, callback) => {
    if (!value) {
      callback(new Error('請填寫原始密碼'));
    } else {
      callback();
    }
  };

  const validateConfirmPass = (rule, value, callback) => {
    if (!value) {
      callback(new Error('請再次填寫密碼'));
    } else if (value.trim() != form.newPassword.trim()) {
      callback(new Error('密碼不一致'));
    } else {
      callback();
    }
  };

  const formRef = ref(null);
  const rules = {
    required: [{ validator: required, trigger: 'blur' }],
    confirmPassword: [{ validator: validateConfirmPass, trigger: 'blur' }],
    // theSame: [
    //   {
    //     validator: (rule, value, callback) => {
    //       check().then((res) => {
    //         if (res.code === 1) {
    //           callback();
    //         } else {
    //           // callback(new Error('密碼與上次設定重複'));
    //           callback(new Error('密碼與上次設定重複'));
    //         }
    //       });
    //     },
    //     trigger: 'blur',
    //   },
    // ],
  };

  const submit = () => {
    return new Promise((resolve) => {
      formRef.value.validate((_valid) => {
        if (_valid) {
          loading.value = true;
          User.changePass({ ...form }).then((res) => {
            if (res.code === 1) {
              ElMessage('成功修改密碼');
            } else {
              const errors = {
                '001': '現在的密碼錯誤',
              };
              ElMessage({
                type: 'error',
                message: errors[res.errorCode] || '資料錯誤',
              });
            }
            loading.value = false;
            return res;
          });
        } else {
          return resolve({ code: 0 });
        }
      });
    });
  };

  return { loading, submit, form, rules, formRef };
};
