<template>
  <div v-loading="loading" class="my-12 container !max-w-2xl">
    <div class="flex flex-col items-center">
      <div class="text-3xl">更改密碼</div>
      <div class="text-xl mt-4">
        為了保護你帳號的安全，請不要分享你的密碼給其他人
      </div>
    </div>
    <El-Form ref="formRef" :model="form" class="mt-6" @validate="onValidate">
      <div class="bg-white rounded shadow p-12 mt-6 space-y-4">
        <El-Form-Item
          label="現在的密碼 :"
          prop="oriPassword"
          :rules="rules['required']"
        >
          <el-input
            v-model="form.oriPassword"
            class="flex-grow w-full"
            placeholder="請輸入密碼"
            type="password"
            :show-password="true"
          >
          </el-input>
        </El-Form-Item>

        <Password
          v-model="form.newPassword"
          :label="'新的密碼 : '"
          :prop-name="'newPassword'"
          :strong="true"
        />

        <El-Form-Item
          label="確認密碼 : "
          prop="chkPassword"
          :rules="rules['confirmPassword']"
        >
          <El-Input
            v-model="form.chkPassword"
            :show-password="true"
            type="password"
            autocomplete="off"
            placeholder="再次輸入新密碼"
          ></El-Input>
        </El-Form-Item>
      </div>

      <div class="mt-8 flex justify-center">
        <el-button
          type="text"
          size="large"
          @click="$router.push({ path: '/account/home' })"
          >稍後再說</el-button
        >

        <el-button
          :disabled="!valid"
          type="primary"
          size="large"
          @click="onSubmit"
          >確認</el-button
        >
      </div>
    </El-Form>
  </div>
</template>
<script setup>
import useResetPassword from '@/modules/auth/useResetPassword.js';
import Password from '@/components/form/Password.vue';
import { ElFormItem, ElForm, ElInput } from 'element-plus';
import { useRouter } from 'vue-router';
import useValid from '@/hook/useValid';
const { loading, submit, form, rules, formRef } = useResetPassword();
const { valid, onValidate } = useValid(form);

const router = useRouter();

const onSubmit = () => {
  submit().then((res) => {
    if (res.code === 1) {
      router.push({ name: 'Account.Home' });
    }
  });
};
</script>

<style lang="scss" scoped></style>
